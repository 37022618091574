import { render, staticRenderFns } from "./Payment.vue?vue&type=template&id=1f787cea&scoped=true"
import script from "./Payment.vue?vue&type=script&lang=js"
export * from "./Payment.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f787cea",
  null
  
)

export default component.exports